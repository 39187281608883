<template>
  <v-card>
    <v-toolbar color="blue lighten-4">
      <v-toolbar-title>Диаграмма питания</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="!value.length || value.length === 1" class="text-center">
      Не достаточно данных
    </v-card-text>
    <v-sparkline
      :value="value"
      :gradient="['#00c6ff', '#F0F', '#FF0']"
      :smooth="16"
      :line-width="2"
      stroke-linecap="round"
      :auto-line-width="true"
      auto-draw
    />
    <v-card-text>
      <v-select
        v-model="itemRu"
        :items="items"
        menu-props="auto"
        hide-details
        label="Выбрать параметр"
      />
    </v-card-text>
  </v-card>
</template>

<script>
// ['#222'],
// ['#42b3f4'],
// ['red', 'orange', 'yellow'],
// ['purple', 'violet'],
// ['#00c6ff', '#F0F', '#FF0'],
// ['#f72047', '#ffd200', '#1feaea'],
export default {
  name: 'Sparklines',
  props: {
    meals: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    itemRu: 'Чувство голода',
    items: ['Чувство голода', 'Объем пищи (гр./мл.)', 'Удовлетворенность от еды (10-бальная шкала)']
  }),
  computed: {
    itemEn() {
      switch (this.itemRu) {
        case 'Чувство голода':
          return 'hunger'
        case 'Объем пищи (гр./мл.)':
          return 'volume'
        case 'Удовлетворенность от еды (10-бальная шкала)':
          return 'satisfaction'
        default:
          return 'hunger'
      }
    },
    value() {
      return this.meals.map(i => +i[this.itemEn])
    }
  }
}
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
